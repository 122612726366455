import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"taalquestions",
        "id":"game_question_id",
        "subid":"game_question_game_id",
        "options":["edit","insert","delete"],
        "nameSingle":"question",
        "nameMultiple":"questions",
        "subidname":"game_question_text",
        "order":"game_question_order",
        "orderArrows":true,
        "extraoptions":[
            {
                "name":"Options",
                "page":"/textgameoptions",
            }
        ],
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field0":{
                "name":"Type",
                "field":"game_question_type",
                "type":"DropDown",
                "options": {
                    "text":"Text",
                    "tutorial":"Tutorial"
                },
                "required":true,
                "list":true,

            },
            "field0b":{
                "name":"Language Sound (OGG)",
                "field":"game_question_audio",
                "type":"SoundUpload",
                "required":false,
                "list":true,
            },
            "field1":{
                "name":"Vraag",
                "field":"game_question_text",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field2":{
                "name":"Language 1",
                "field":"game_question_flag1",
                "type":"DropDown",
                "options": "countries",
                "required":false,
                "list":false,
            },
            "field3":{
                "name":"Language 1 text",
                "field":"game_question_text2",
                "type":"TextInput",
                "required":false,
                "list":false,
            },
            "field4":{
                "name":"Language 2",
                "field":"game_question_flag2",
                "type":"DropDown",
                "options": "countries",
                "required":false,
                "list":false,
            },
            "field5":{
                "name":"Language 2 text",
                "field":"game_question_text3",
                "type":"TextInput",
                "required":false,
                "list":false,
            }            
        }
    }
    
}